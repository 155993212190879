.blur-1 {
  filter: blur(2px);
}

.blur-2 {
  filter: blur(2px);
}

.blur-3 {
  filter: blur(2px);
}

.blur-4 {
  filter: blur(2px);
}

.blur-5 {
  filter: blur(2px);
}

.blur-6 {
  filter: blur(2px);
}

.blur-7 {
  filter: blur(2px);
}

.blur-8 {
  filter: blur(3px);
}

.blur-9 {
  filter: blur(8px);
}

.blur-10 {
  filter: blur(12px);
}

.blur-11 {
  filter: blur(16px);
}

.blur-12 {
  filter: blur(22px);
}

.blur-13 {
  filter: blur(30px);
}

.flex {
  display: flex;
}

.flex.col {
  flex-direction: column;
}

.map-header {
  background: #303952;
  padding-bottom: 1em;
  margin-bottom: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 0.25em 0.25em 1em;
}

.map-name {
  margin: 1rem 0.25rem;
}

.map-link {
  margin: 0.25em;
}

.map-text {
  margin: 0.25em;
}

.person-tooltip {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  z-index: 9999;
}

.person-tooltip span {
  display: block;
  border-radius: 0.25em;
  padding: 0.25em;
  margin: 0 0 0.5em 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.person-list {
  flex: 1;
  margin: 1em 0 0;
}

.person-list p {
  padding: 0.5em;
  margin: 0;
  cursor: pointer;
  color: #fff;
}

.person-list p:hover {
  background: #303952;
}

.person-cards {
  position: absolute;
  bottom: 0.5em;
  left: 0;
  z-index: 1001;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  scroll-padding-left: 0.25em;
}

.person-card {
  background: #fff;
  margin: 0 0.25em 0.5em;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding-bottom: 0.5em;
  scroll-snap-align: start;
  min-height: 8em;
  display: inline-block;
  scroll-snap-align: center;
  vertical-align: bottom;
}

.person-card header {
  font-weight: 700;
  padding: 0.25em;
  margin-bottom: 0.5em;
  background: #303952;
  border-radius: 4px 4px 0 0;
  color: #fff;
  line-height: 2em;
}

.person-card a {
  padding: 0.25em;
}

.person-card .text {
  padding: 0.25em;
  margin: 0;
  white-space: pre-wrap;
  max-height: 10em;
}