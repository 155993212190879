.invitation {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  height: 100vh;
}

.wrapper {
  background: #fff;
  padding: 1em;
  border-radius: 0.25em;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

form {
  display: flex;
  flex-direction: column;
}

input, button {
    padding: 0.25em;
    margin-bottom: 0.25em;
}
