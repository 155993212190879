form {
  padding: 1em;
}

form label {
    display: block;
    line-height: 2em;
}

form label span {
    display: inline-block;
    width: 15em;
}

input, select {
    padding: 0.25em;
}

table {
    border-collapse: collapse;
    margin: 1em;
}

td, th {
    padding: 0.25em;
    border: 1px solid #ccc;
    font-size: 1em;
}

input[type='text']:read-only {
    min-width: 50vw;
    font-family: monospace;
}