.leaflet-draw.leaflet-control:not(:first-child) {
  display: none;
}

label {
  display: flex;
  flex-direction: column;
}

.save-success {
  background-color: rgb(25, 201, 63);
  color: rgb(12, 56, 22);
  padding: 0.5em;
  margin: 0.5em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}