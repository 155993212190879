body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  line-height: 1.5em;
}

.error {
  background: rgb(196, 31, 31);
  color: #fff;
  padding: 0.5em;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1em);
  z-index: 1001;
}

a,
a:link,
a:visited {
  color: #1068a3;
}

a:hover,
a:focus {
  color: #09f;
}

.leaflet-control-container a,
.leaflet-control-container a:link,
.leaflet-control-container a:visited {
  color: #333;
}

.leaflet-control-container a:hover,
.leaflet-control-container a:focus {
  color: #000;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background: #596275;
  border-left: 2px solid #303952;
  height: 100vh;
  z-index: 1002;
}

.sidebar-toggle {
  border: 2px solid #303952;
  border-right-color: #596275;
  border-radius: 4px 0 0 4px;
  background: #596275;
  padding: 1em 4px 1em 0.25em;
  height: 4em;
  position: absolute;
  top: calc(50vh - 2em);
  z-index: 1003;
  cursor: pointer;
  outline: none;
}

.sidebar-toggle.opened {
  right: 279px;
}

.sidebar-toggle.closed {
  right: 0;
}
